/* Project variables
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import "_variables";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-sans-serif;
  margin: 0;

  > main {
    opacity: 0;
    transition: opacity 1s ease;
  }

  > .loading {
    opacity: 1;
    transition: opacity 1s ease;
  }

  &.page-loaded {
    > main {
      opacity: 1;
    }

    > .loading {
      opacity: 0;
      z-index: -1;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .container-xl {
    max-width: 1580px;
    padding: 0 50px;
  }
}

/* Silver bootstrapping
-------------------------------------------------------- */
@import "partials/helpers";

/* Vendor imports
-------------------------------------------------------- */
@import "vendor/ion.rangeSlider/ion.rangeSlider";
@import "vendor/slick";
@import "vendor/simplebar.css";

/* Typography
-------------------------------------------------------- */

.serif {
  font-family: $font-family-serif;
}

.sans-serif {
  font-family: $font-family-sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family-sans-serif;
  font-weight: $headings-font-weight;
  color: #fff;
}

p,
ul,
li {
  font-family: "Roboto", sans-serif;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 7.2px;
  margin: 24px 0;

  @media (min-width: $screen-sm-min) {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 9.6px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 10.8px;
  }

  @media (min-width: $screen-xl-min) {
    font-size: 42px;
    line-height: 53px;
    letter-spacing: 12.6px;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
  margin: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) 0;

  @media (min-width: 1920px) {
    font-size: 60px; // cap the font size.
  }
}

h3,
.h3 {
  font-size: $font-size-h3;
  margin: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) 0;

  @media (min-width: 1920px) {
    font-size: 25px; // cap the font size.
  }
}

h4,
.h4 {
  font-size: $font-size-h4;
  color: $brand-success;
  margin: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;

  @media (min-width: 1920px) {
    font-size: 15px; // cap the font size.
  }
}

h5,
.h5 {
  font-size: $font-size-h5;
  font-weight: 400;
  margin: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
}

h6,
.h6 {
  font-size: $font-size-h6;
  color: $brand-success;
  margin: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
}

p {
  color: #fff;
  font-weight: 400;
}

.highlight {
  color: $brand-highlight;
}

p.medium,
span.medium {
  font-weight: 400;
}

p.success,
span.success {
  color: $brand-success;
}

b,
strong {
  font-weight: 700;
}

a {
  font-weight: bold;
}

section {
  padding-top: 130px;
}

.text-black {
  color: #000;

  p,
  ul li,
  ol li,
  h1,
  h2,
  h3,
  h5 {
    color: #000;
  }
}

.section-title {
  margin-top: 0;
}

/* List Styling
-------------------------------------------------------- */
ul,
ol {
  color: #fff;
  font-weight: 400;

  &.black {
    color: #000;
  }
}

ol {
  counter-reset: item;
  padding-left: 0;
}

ol li,
ul li {
  display: block;
  margin-bottom: 5px;
  clear: both;
  position: relative;

  &::before {
    display: inline-block;
    height: 25px;
    float: left;
  }
}

ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  color: $brand-success;
  padding-right: 10px;
  font-weight: 700;
}

ul {
  list-style: none;
  padding-left: 0;

  li::before {
    content: "\2022";
    color: $brand-success;
    padding-right: 10px;
    font-weight: 700;
  }
}

/* Buttons
-------------------------------------------------------- */
button,
a.btn {
  display: inline-block;
  box-shadow: none;
  background: $brand-primary;
  color: #fff;
  border-radius: 0;
  text-transform: none;
  padding: 11px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  margin: 30px 20px 0 0;
  align-items: flex-start;
  font-family: $font-family-sans-serif;
  text-align: center;
  vertical-align: middle;
  border: 1px solid $brand-primary;
  transition: all 0.25s ease;

  img {
    transition: all 0.25s ease;
  }

  &:hover {
    color: #fff;
    background: #006cc3;
  }

  &.secondary {
    background: transparent;
    border-color: #fff;

    &:hover {
      background: #fff;
      color: $brand-primary;
    }
  }

  &.transparent {
    background: transparent;
    border: none;
    padding-left: 0;

    &:active,
    &:focus {
      outline: none;
    }
  }

  &.open img {
    transform: translate(5px, -2px) rotate(180deg) !important;
  }

  @media (max-width: 370px) {
    margin-right: 5px;
  }

  @media (min-width: $screen-sm-min) {
    font-size: 16px;
    padding: 20px 55px;
    margin-right: 20px;
  }
}

.fade-left {
  animation: animationFadeleft ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: animationFadeleft ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFadeleft ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: animationFadeleft ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: animationFadeleft ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}

@keyframes animationFadeleft {
  0% {
    opacity: 0;
    transform: translate(79px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -moz-transform: translate(79px, 0px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translate(79px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -o-transform: translate(79px, 0px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -ms-transform: translate(79px, 0px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

.fade-right {
  animation: animationFaderight ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: animationFaderight ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFaderight ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: animationFaderight ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: animationFaderight ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}

@keyframes animationFaderight {
  0% {
    opacity: 0;
    transform: translate(-80px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes animationFaderight {
  0% {
    opacity: 0;
    -moz-transform: translate(-80px, 0px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes animationFaderight {
  0% {
    opacity: 0;
    -webkit-transform: translate(-80px, 0px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes animationFaderight {
  0% {
    opacity: 0;
    -o-transform: translate(-80px, 0px);
  }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes animationFaderight {
  0% {
    opacity: 0;
    -ms-transform: translate(-80px, 0px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

/* Loading
-------------------------------------------------------- */
.loading {
  min-height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .spinner {
    $size: 50px;
    $border: 5px;
    $primary: $brand-info;
    $secondary: rgba($brand-info, 0.2);

    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin-top: -($size/2);
    margin-left: -($size/2);
    border: $border solid $primary;
    border-color: $primary $secondary $secondary $secondary;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.text {
  &-brand-secondary {
    color: $brand-secondary;
  }

  &-success {
    color: $brand-success !important;
  }

  &-success-action {
    color: $brand-success-action;
  }

  &-bold {
    font-weight: 700;
  }
}

input[type="radio"]:disabled {
  background: #ccc;
}

/* Partials
-------------------------------------------------------- */
@import "partials/bootstrap";
@import "partials/masthead";
@import "partials/range-slider";
@import "components/stage-navigation";
@import "components/styled-dropdown";
@import "components/progress-tooltip";
@import "partials/bootstrap";
@import "partials/range-slider";
@import "components/form-navigation";
@import "components/stage-navigation";
@import "components/styled-dropdown";
@import "components/progress-tooltip";

.sort-code {
  .form-control {
    display: inline-block;
    width: 50px;
    text-align: center;
  }

  span {
    color: $gray-light;
    margin: 0 10px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Views
-------------------------------------------------------- */
@import "./views/video";
@import "./views/stages";
@import "./views/assets";
@import "./views/quick-ref";
@import "./views/404";

/* Components
-------------------------------------------------------- */

@import "./partials/footer.scss";
@import "./partials/animations.scss";
