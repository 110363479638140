#assets {
	background-color: $gray-dark;
	background-position: center;
	background-size: cover;
	padding-top: 0;
	min-height: 100vh;
	flex-flow: column;
	justify-content: space-between;


	h1 {
		padding: 0 2.5%;
		letter-spacing: 7.2px;
		margin-top: 5px;

		&.dark {
			color: $gray-dark;
		}
	}

	.asset-page-number {
		display: flex;
		margin: 0 auto;
		background-image: url(/images/hexagon.svg);
		background-size: contain;
		background-repeat: no-repeat;
		color: #fff;
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 10px;
		font-weight: 900;
		align-content: center;
		justify-content: center;
		padding-left: 1px;
	}

	.powerstore {
		display: block;
		font-size: 12px;
		line-height: 24px;
		text-transform: uppercase;
		text-align: center;
		color: #fff;
		padding-top: 5px;
		font-weight: 500;
	}

	.asset-tile-wrapper {
		padding: 10px;
	}

	.asset-tile {
		display: block;
		background-color: rgba(255, 255, 255, 0.9);
		height: 190px;
		transition: background-color 0.25s;

		&:hover {
			background-color: rgba(255, 255, 255, 1);
		}

		&.is-tip {
			background-color: rgba($brand-info, 0.9);
			//background-image: url(/images/icons/icon-lightbulb.svg);
			background-repeat: no-repeat;
			background-position: bottom 10px right 5px;
			background-size: 40px;
			padding: 27px 15px;
			height: auto;

			.tip-title {
				font-size: 12px;
				line-height: 29px;
				font-weight: 500;
				text-transform: uppercase;
				color: #fff;
			}

			p {
				margin: 10px 0 0 0;
				font-size: 20px;
				line-height: 26px;
			}

			.source {
				color: #fff;
				font-size: 11px;
				font-weight: 500;
				line-height: 15px;
				text-decoration: none;
				display: block;
				margin-bottom: 5px;
				padding: 20px 0 0 0;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		a {
			display: flex;
			flex-direction: column;
			color: $gray-dark;
			text-decoration: none;
			padding: 17px 10px;
			height: 100%;
		}

		.asset-type {
			display: block;
			font-size: 12px;
			line-height: 14px;
			font-weight: 500;
			letter-spacing: 0.6px;
			margin-bottom: 5px;
		}

		.asset-name {
			display: block;
			font-size: 12px;
			line-height: 20px;
			font-weight: 900;
			letter-spacing: 1.4px;
			flex-grow: 1;
		}

		.download {
			font-size: 14px;
			line-height: 14px;
			color: $link-color;
			font-weight: 400;

			img,
			svg {
				width: 15px;
				margin-left: 8px;
				vertical-align: baseline;

				@media (max-width: 349px) {
					display: none; // At this device scale the icon normally breaks onto a new line.
				}
			}
		}

		.icon {
			height: 33px;
			margin-bottom: 18px;
			align-self: flex-start;

			&.banner {
				padding-bottom: 5px;
			}

			&.email {
				padding-bottom: 6px;
			}

			&.player {
				padding-bottom: 3px;
			}
		}
	}

	.next-stage {
		padding: 10px;

		.box {
			background: rgba($gray-dark, 0.9);
			color: #fff;
			margin: 25px 0 80px;
			position: relative;

			figure {
				background-size: cover;
				background-position: center;
				width: 100%;
				padding-bottom: 56%;
				margin-bottom: 0;
			}

			main {
				padding: 27px 20px;

				@media (max-width: 370px) {
					padding: 22px 9px;
				}
			}

			span {
				display: block;
			}

			.proceed {
				font-size: 12px;
				line-height: 14px;
				letter-spacing: 0.6px;
				font-weight: 500;
			}

			.stage-name {
				margin-top: 15px;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 5.4px;
				font-weight: 900;
			}

		}
	}

	.sources {

		a {
			color: #fff;
			font-size: 11px;
			font-weight: 500;
			line-height: 15px;
			text-decoration: none;
			display: block;
			margin-bottom: 5px;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	/** TABLET STYLES **/
	@media (min-width: $screen-sm-min) {

		.asset-page-number {
			width: 45px;
			height: 45px;
			line-height: 45px;
			font-size: 20px;
		}

		h1 {
			margin-bottom: 35px;
			margin-top: 24px;
		}

		.asset-tile-wrapper {
			padding: 22.5px;
		}


		.asset-tile {
			height: 100%;
			min-height: 246px;

			&.is-tip {
				padding: 30px 26px;
				height: auto;
				background-position: bottom 15px right 10px;
				background-size: 50px;

				.tip-title {
					font-size: 14px;
					line-height: 29px;
					letter-spacing: 0.7px;
				}

				p {
					margin: 16px 0 0 0;
					font-size: 28px;
					line-height: 36px;
				}
			}

			a {
				padding: 30px 22px;
			}

			.asset-type {
				font-size: 14px;
				line-height: 29px;
				letter-spacing: 0.7px;
			}

			.asset-name {
				font-size: 20px;
				line-height: 28px;
				letter-spacing: 2px;
			}

			.download {
				font-size: 16px;
				line-height: 16px;
			}

			.icon {
				height: 40px;
				margin-bottom: 15px;
			}

			.download {

				img,
				svg {
					width: 19px;
				}
			}
		}

		.next-stage {
			padding: 20px;

			.box {
				margin: 25px 0;

				main {
					padding: 78px 61px 67px;
				}

				.proceed {
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 0.7px;
				}

				.stage-name {
					font-size: 28px;
					line-height: 36px;
					letter-spacing: 8.4px;
				}
			}
		}

		.sources {
			padding: 20px;
		}

	}


	/** LAPTOP STYLES **/
	@media (min-width: $screen-lg-min) {

		.next-stage .box {
			display: flex;
			flex-flow: row;
			flex-direction: row-reverse;

			figure {
				width: 50%;
				margin-bottom: 0;
				padding-bottom: 24%;
			}

			main {
				padding: 71px 20px 47px 61px;
				width: 50%
			}

			.proceed {
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.7px;
			}

			.stage-name {
				font-size: 28px;
				line-height: 36px;
				letter-spacing: 8.4px;
			}
		}
	}


	/** DESKTOP STYLES **/
	@media (min-width: $screen-xl-min) {

		.asset-page-number {
			width: 65px;
			height: 65px;
			line-height: 65px;
			font-size: 32px;
		}

		.asset-tile {
			min-height: 280px;

			&.is-tip {
				height: 100%;

				p {
					font-size: 31px;
					line-height: 42px;
				}
			}

		}

		.next-stage .box {

			main {
				padding: 106px 61px 94px;
				width: 50%
			}

			.proceed {
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.7px;
			}

			.stage-name {
				font-size: 28px;
				line-height: 36px;
				letter-spacing: 8.4px;
			}
		}

	}

}
