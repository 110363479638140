nav.form-navigation {
  margin-bottom: 40px;

  /* Content
  -------------------------------------------------------- */
  .content {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  /* Navigation dots
  -------------------------------------------------------- */
  ul.dots {
    padding: 0;
    margin: 0;
    display: block;
    text-align: justify;
    height: 40px;

    &::after {
      content: '';
      width: 100%;
      display: inline-block;
      font-size: 0;
      line-height: 0;
    }

    li {
      padding: 0;
      margin: 0;
      position: relative;
      display: inline-block;
      line-height: 40px;

      $dot-size: 10px;

      a,
      span {
        display: inline-block;
        width: $dot-size;
        height: $dot-size;
        text-indent: -999px;
        overflow: hidden;
        border-radius: 50%;
        background: $brand-primary;

        &.disabled {
          cursor: auto;
          background: $gray-light;
          pointer-events: none;
        }

        &.active {
          transform: scale(2);
        }
      }

      &.progress-item {
        margin: 0;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        right: 10px;
        height: 4px;
        background: $gray-light;
        pointer-events: none;

        .fill {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background: $brand-primary;
        }
      }
    }
  }
}
