#footer {
	background: transparent;
	width: 100%;

	.footer-logo-container {
		width: 100%;
		display: flex;
		padding-top: 31px;
		justify-content: flex-end;

		.intel-logo-footer {
			width: 116px;
		}
	}

	.footer-copyright {
		width: 100%;
		text-align: center;
		padding-top: 31px;
		padding-bottom: 75px;

		p {
			font-size: 14px;
			color: #444;
		}
	}

	&.white {

		.footer-copyright p {
			color: #fff;
		}
	}

	// TABLET
	@media (min-width: $screen-sm-min) {
		.footer-logo-container {
			padding-top: 40px;

			.intel-logo-footer {
				width: 141px;
			}
		}

		.footer-copyright {
			padding-top: 33px;
			padding-bottom: 15px;

			p {
				font-size: 14px;
			}
		}
	}

	// LAPTOP
	@media (min-width: $screen-md-min) {
		.footer-logo-container {
			img {
				position: absolute;
				right: 50px;
			}
		}

		.footer-copyright {
			padding-top: 33px;
			padding-bottom: 15px;

			p {
				font-size: 16px;
			}
		}
	}

	// DESKTOP
	@media (min-width: $screen-lg-min) {
		.footer-logo-container {
			padding-top: 40px;

			.intel-logo-footer {
				width: 200px;
			}
		}

		.footer-copyright {
			padding-top: 41px;
			padding-bottom: 20px;
		}
	}
}
