section#stages {
  font-family: $font-family-sans-serif;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  background-image: url(/images/backgrounds/Web_background@2x.jpg);
  padding-top: 0;

  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-between;

  .stages-options {
    margin: 0 auto;

    .title-container {
      margin-bottom: 30px;

      h1 {
        color: $gray-dark;
        font-size: 24px;
        letter-spacing: 7px;
        margin: 0;
      }

      p {
        color: $gray-dark;
        font-size: 12px;
        font-weight: 500;
      }
    }

    @keyframes octo-frame {
      0% {
        stroke: transparent;
        fill: transparent;
      }
      50% {
        stroke: transparent;
        fill: transparent;
      }
      100% {
        stroke: transparent;
        fill: transparent;
      }
    }

    @keyframes octo-play {
      0% {
        stroke: transparent;
        fill: transparent;
      }
      50% {
        stroke: transparent;
        fill: transparent;
      }
      100% {
        stroke: #ffffff;
        fill: #ffffff;
      }
    }

    @keyframes octo {
      0% {
        stroke: transparent;
        fill: transparent;
      }
      100% {
        stroke: transparent;
        fill: #0069b5;
      }
    }

    .stages-container {
      padding: 0;
      justify-content: space-between;
      margin: 0 auto;

      .stages-pairs {
        justify-content: space-between;

        .stage-ind {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          width: 47%;

          &:hover {
            .stage-info {
              p,
              h2,
              a {
                color: #fff;
              }

              svg path {
                fill: #fff !important;
              }
            }

            .video-section {
              cursor: pointer;
              .gif-preview {
                opacity: 1;
              }

              .image-preview {
                opacity: 0;
              }

              .octagon {
                opacity: 1;
                border: 0;

                .octagon-poly {
                  animation: octo;
                  animation-duration: 0.4s;
                  animation-fill-mode: forwards;
                }
                .octagon-frame {
                  animation: octo-frame;
                  animation-duration: 0.4s;
                  animation-fill-mode: forwards;
                }
                .octagon-play {
                  animation: octo-play;
                  animation-duration: 0.4s;
                  animation-fill-mode: forwards;
                }
              }
            }
          }

          &.awareness {
            &:hover {
              .stage-info {
                background-color: #41b6e6;
              }
            }
          }

          &.consideration {
            &:hover {
              .stage-info {
                background-color: #f2af00;
              }
            }
          }

          &.conversation {
            &:hover {
              .stage-info {
                background-color: #6ea204;
              }
            }
          }

          &.after-sales {
            &:hover {
              .stage-info {
                background-color: #ee6411;
              }
            }
          }

          .video-section {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .gif-preview {
              transition: opacity 0.2s ease;
              opacity: 0;
              object-fit: cover;
              object-position: center;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }

            .image-preview {
              transition: opacity 0.2s ease;
              opacity: 1;
              object-fit: cover;
              object-position: center;
              height: 100%;
              width: 100%;
            }

            .octagon {
              width: 65px;
              height: 65px;
              display: block;
              position: absolute;
              background-image: url("../../images/svg/octagon.svg");
              transition: opacity 0.25s ease-in-out;
              opacity: 0;
              border: 0 !important;

              .octagon-poly,
              .octagon-frame,
              .octagon-play {
                /*stroke: transparent;
                fill: transparent;*/
              }
            }
          }

          .stage-info {
            transition: background-color 0.2s ease;
            background-color: #f3f3f3;
            text-align: center;
            padding: 12px 0;
            cursor: pointer;
            p,
            h2 {
              color: $gray-dark;
              margin-bottom: 5px;
              margin-top: 5px;
              transition: color 0.2s ease;
            }

            p {
              font-size: 12px;
            }

            h2 {
              font-size: 13px;
              text-transform: uppercase;
              letter-spacing: 1.5px;
            }

            .stage-number {
              font-weight: 500;
            }

            .preview-link {
              color: #0076ce;
              font-size: 14px;
              text-decoration: none;
              font-weight: 400;
              cursor: pointer;
              svg path {
                fill: #0076ce;
              }
            }
          }
        }
      }
    }

    .sub-boxes-container {
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0 auto;

      .sub-box {
        background-color: #0076ce;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 20px;
        padding: 21px 0;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #0069b5;
        }

        .description {
          font-size: 12px;
          color: #fff;
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 500;
        }

        .title {
          font-size: 15px;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          color: #fff;
          margin: 10px auto 15px;
          width: 240px;
          text-align: center;
        }

        .sub-box-links {
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          justify-content: center;

          a {
            color: #fff;
            text-decoration: none;
            font-weight: normal;
            font-size: 10px;
            margin: 0 5px;
          }

          img {
            width: 16px;
          }
        }
      }
    }
  }

  // TABLET
  @media (min-width: $screen-sm-min) {
    padding-top: 0;


    header#masthead .replay span:after {
      width: 74%;
      margin: 8px auto 0;
    }

    .stages-options {
      margin: 0 auto;
      max-width: 609px;

      .title-container {
        margin-bottom: 47px;

        h1 {
          font-size: 32px;
          letter-spacing: 9.6px;
          white-space: nowrap;
        }

        p {
          font-size: 12px;
        }
      }

      .stages-container {
        padding: 0;

        .stages-pairs {
          .stage-ind {
            margin-bottom: 45px;
            width: 47%;

            &:first-child {
              margin-right: 45px;
            }

            .video-section {
              background-color: $brand-primary;
            }

            .stage-info {
              padding: 19px 0;

              p,
              h2 {
                color: #444;
                margin-bottom: 7px;
                margin-top: 7px;
              }

              .stage-number {
                font-size: 14px;
              }

              h2 {
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: 2px;
              }

              .preview-link {
                color: $brand-primary;
                font-size: 16px;
              }
            }
          }
        }
      }

      .sub-boxes-container {
        .sub-box {
          margin-bottom: 45px;
          padding: 39px 0;

          .description {
            font-size: 14px;
          }

          .title {
            font-size: 20px;
            width: 90%;
          }

          .sub-box-links {
            a {
              font-size: 16px;
              margin: 0 14px;
            }
          }
        }
      }
    }
  }

  // LAPTOP
  @media (min-width: $screen-md-min) {
    padding-top: 0;

    .stages-options {
      max-width: 960px;

      .stages-container {
        .stages-pairs {
          .stage-ind {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            width: 47%;

            .stage-info {
              h2 {
                font-size: 18px;
              }
            }
          }
        }
      }

      .sub-boxes-container {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;

        .sub-box {
          height: 100%;

          .sub-box-links {
            a {
              margin: 0 20px;
            }
          }
        }
      }
    }
  }

  // DESKTOP
  @media (min-width: 1366px) {
    .stages-options {
      max-width: 1263px;

      .stages-container {
        .stages-pairs {
          .stage-ind {
            .stage-info {
              h2 {
                font-size: 20px;
              }
            }
          }

          .video-section {
            height: 160px;
          }
        }
      }

      .title-container {

        margin-top: -65px;

        h1 {
          color: #444;
          font-size: 36px;
          letter-spacing: 9.6px;
          white-space: nowrap;
          margin-top: 17px;
          line-height: 1;
        }

        p {
          color: #444;
          font-size: 14px;
          margin-bottom: 0;
          margin-top: 30px;
        }
      }

      .sub-boxes-container {
        .sub-box {
          padding: 16px 0;

          .title {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  // XL DESKTOP

  @media (min-width: $screen-xl-min) {
    .stages-options {
      max-width: 1419px;

      .title-container {

        p {
          font-size: 18px;
        }

        h1 {
          font-size: 42px;
          letter-spacing: 9.6px;
          margin-top: 21px;
        }

        p {
          font-size: 18px;
          margin-bottom: 0;
        }
      }

      .sub-boxes-container {
        .sub-box {
          padding: 25px 0;

          .description {
            font-size: 14px;
          }

          .title {
            font-size: 23px;
            letter-spacing: 2px;
            width: 90%;
          }

          .sub-box-links {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
